<template>
  <b-container fluid class="bg-light" v-if="signedIn">
    <!-- REG HORIZON -->
    <b-row class="text-white py-4 mb-4" style="background-color: #440965; background-image:url(/img/uk_background_image.jpg); background-position: right; background-repeat: no-repeat;">
      <b-col>
        <b-container>
          <b-row>
            <b-col>
                <h1 class="kstyle mb-4">Covid-19 regulatory updates</h1>
                <div>Number of records loaded: {{news.length}}</div>
            </b-col>
          </b-row>
          <transition appear name="fade">
          <b-row v-if="loading" class="text-center text-white">
            <b-col>
              <div style="min-height: 500px;">
                <h1 class="mb-4">loading data</h1>
                <h1><i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i></h1>
              </div>
            </b-col>
          </b-row>
          </transition>
        </b-container>
      </b-col>
    </b-row>
    <transition appear name="fade">
    <b-row v-if="!loading" class="bg-white pb-4">
      <b-col>
        <b-container v-if="show.start">
          <b-row>
            <b-col class="p-4 m-2 text-white bg-kpmg-light-blue">
              <h2><b-link class="text-white" :to="{ name: 'Covid', params: { page: 'all' } }">All</b-link></h2>
              <div>All news</div>
            </b-col>
            <b-col class="p-4 m-2 text-white bg-kpmg-light-purple">
                  <h2><b-link class="text-white" :to="{ name: 'Covid', params: { page: 'coreUK' } }">UK Core</b-link></h2>
                  <div>FCA, PRA and Bank of Engalnd</div>
            </b-col>
            <b-col class="p-4 m-2 text-white bg-kpmg-medium-blue">
                  <h2><b-link class="text-white" :to="{ name: 'Covid', params: { page: 'tag', id: 1 } }">Dear CEO Letters</b-link></h2>
                  <div>Letters issued in relation to Covid-19</div>
            </b-col>
          </b-row>
          <b-row>
            <b-col class="p-4 m-2 text-white bg-kpmg-light-green">
              <h2><b-link class="text-white" :to="{ name: 'Covid', params: { page: 'tag', id: 20 } }">Extended deadlines</b-link></h2>
              <div>Changes to regulatory deadlines due to Covid-19</div>
            </b-col>
            <b-col class="p-4 m-2 text-white bg-kpmg-dark-blue">
                  <h2><b-link class="text-white" :to="{ name: 'Covid', params: { page: 'tag', id: 5 } }">Policy statements</b-link></h2>
                  <div>New policies in response to Covid-19</div>
            </b-col>
            <b-col class="p-4 m-2 text-white bg-kpmg-purple">
                  <h2><b-link class="text-white" :to="{ name: 'Covid', params: { page: 'tag', id: 18 } }">Guidance</b-link></h2>
                  <div>Regulatory guidance specific to Covid-19 response</div>
            </b-col>
          </b-row>
        </b-container>
        <b-container v-if="show.news">
          <b-row>
            <b-col cols="2" class="nav-special">
              <b-link :to="{ name: 'Covid', params: { page: 'all' } }"><div class="p-1">All</div></b-link>
              <b-link :to="{ name: 'Covid', params: { page: 'coreUK' } }"><div class="p-1">FCA, PRA, BoE</div></b-link>
              <div role="tablist">
                <div header-tag="header" role="tab" v-b-toggle.accordion-1 class="p-1 mt-2 cursor-pointer"><strong>Countries</strong></div>
                <b-collapse id="accordion-1" role="tabpanel" accordion="my-accordion">
                  <div v-for="(item, index) in countries" :key="'countryLink' + index">
                    <b-link :to="{ name: 'Covid', params: { page: 'country', id: item } }"
                      ><div class="p-1">{{ item }}</div></b-link
                    >
                  </div>
                </b-collapse>
                <div header-tag="header" role="tab" v-b-toggle.accordion-2 class="p-1 mt-2 cursor-pointer"><strong>Sources</strong></div>
                <b-collapse id="accordion-2" role="tabpanel" accordion="my-accordion">
                  <div v-for="(item, index) in publishers" :key="'sourceLink' + index">
                    <b-link :to="{ name: 'Covid', params: { page: 'source', id: item } }"
                      ><div class="p-1">{{ item }}</div></b-link
                    >
                  </div>
                </b-collapse>
                <div header-tag="header" role="tab" v-b-toggle.accordion-3 class="p-1 mt-2 cursor-pointer"><strong>Tags</strong></div>
                <b-collapse id="accordion-3" role="tabpanel" accordion="my-accordion">
                  <div v-for="item in tags" :key="'tagLink' + item.id">
                    <b-link :to="{ name: 'Covid', params: { page: 'tag', id: item.id } }"><div class="p-1">{{ item.name }}</div></b-link>
                  </div>
                </b-collapse>
              </div>
            </b-col>
            <b-col>
              <b-container>
                <b-form-row class="mb-3">
                  <b-col cols="10" ><b-form-input v-model="search" placeholder="Search"></b-form-input></b-col>
                  <b-col><b-button variant="outline-primary" v-on:click="searchReset">clear</b-button></b-col>
                </b-form-row>
                <b-form-row class="mb-3">
                  <b-col cols="5"><b-form inline>Start date: <b-form-datepicker id="date-start" v-model="dateStart" size="sm"></b-form-datepicker></b-form></b-col>
                  <b-col cols="5"><b-form inline>End date: <b-form-datepicker id="date-end" v-model="dateEnd" size="sm"></b-form-datepicker></b-form></b-col>
                  <b-col><b-button variant="outline-primary" v-on:click="datesReset">reset</b-button></b-col>
                </b-form-row>
                <b-form-row class="mb-3">
                  <b-col>
                    <b-pagination v-model="currentPage" :total-rows="rows" :per-page="perPage" limit="10"></b-pagination>
                  </b-col>
                </b-form-row>
              </b-container>
              <div v-for="item in showItems" :key="'newsitem' + item.id" class="mb-2">
                <!-- <div class="mb-0"><router-link :to="{ name: 'NewsItem', params: { id: item.id } }">{{ item.name }}</router-link></div> -->
                <div class="mb-0"><b-link v-on:click="itemDisplay(item)">{{ item.name }}</b-link></div>
                <div class="mb-1" style="color: #999999;"><small>{{item.source}} - {{item.date}}</small></div>

              </div>
              <b-modal id="modal" size="lg" ok-only scrollable centered>
                <div><strong>{{item.name}}</strong></div>
                <div><small>{{item.publisher}} - {{item.date}}</small></div>
                <div>{{item.body}}</div>
                <div><a :href="item.link" target="_blank">{{item.link}}</a></div>
              </b-modal>
            </b-col>
          </b-row>
        </b-container>
      </b-col>
    </b-row>
    </transition>
  </b-container>
</template>

<script>
import _ from 'lodash'
import moment from 'moment'
// import vega from 'vega'
// import vegaLite from 'vega-lite'
// import vegaEmbed from 'vega-embed'

export default {
  name: 'Covid',
  created: function () {
    this.firstLoad()
  },
  data () {
    return {
      countires: [],
      currentPage: 1,
      dateStart: '2010-01-01',
      dateEnd: '2020-12-31',
      item: {},
      loading: true,
      news: [],
      newsSelected: [],
      perPage: 10,
      publishers: [],
      search: '',
      show: {
        start: true,
        news: false
      }
    }
  },
  computed: {
    rows () {
      return this.newsSelected.length
    },
    showItems () {
      let dateStart = this.dateStart
      let dateEnd = this.dateEnd
      let search = this.search
      let items = _.filter(this.newsSelected, function (o) {
        return moment(o.date).isSameOrAfter(dateStart) && moment(o.date).isSameOrBefore(dateEnd)
      })
      items = items.filter(item => item.name.toUpperCase().includes(search.toUpperCase()))
      items = _.slice(items, (this.currentPage - 1) * this.perPage, (this.currentPage - 1) * this.perPage + this.perPage)
      return items
    },
    signedIn () {
      return this.userState === 'signedIn'
    },
    user: {
      get () {
        return this.$store.state.user
      }
    },
    userState () {
      return this.$store.state.userState
    }
  },
  methods: {
    all: function () {
      this.newsSelected = this.news
    },
    buildStatic: function () {
      let countries = []
      let publishers = []
      let tags = []
      _.forEach(this.news, function (value, key) {
        countries.push(value.countryName)
        publishers.push(value.publisher)
        tags = _.concat(tags, value.tags)
      })
      countries = _.uniq(countries).sort()
      _.remove(countries, o => { return o === null })
      this.countries = countries
      this.publishers = _.uniq(publishers).sort()
      this.tags = _.uniqBy(tags, 'id').sort()
    },
    country: function () {
      let data = []
      let country = this.$route.params.id
      _.forEach(this.news, function (value, key) {
        if (value.countryName === country) {
          data.push(value)
        }
      })
      this.newsSelected = data
    },
    coreUK: function () {
      let data = []
      _.forEach(this.news, function (value, key) {
        if (value.publisher === 'FCA' || value.publisher === 'BoE' || value.publisher === 'PRA') {
          data.push(value)
        }
      })
      this.newsSelected = data
    },
    datesReset: function () {
      this.dateStart = _.minBy(this.news, function (o) { return o.date }).date
      this.dateEnd = _.maxBy(this.news, function (o) { return o.date }).date
    },
    dearCEO: function () {
      let data = []
      _.forEach(this.news, function (value, key) {
        if (
          _.find(value.tags, function (o) {
            return o.id === '1'
          })
        ) {
          data.push(value)
        }
      })
      this.newsSelected = data
    },
    firstLoad: async function () {
      let id = 83 // this is Covid-19 on prod database
      // change to MAR on local DB
      this.$config.API === 'local' ? id = 1 : id = 83
      let limit = 200
      let offset = 0
      let page = 0
      let apiName = 'cosmos'
      let response = []

      do {
        offset = page * limit
        let path = `/news/topic/full/${id}/limit/${limit}/offset/${offset}`
        response = await this.$Amplify.API.get(apiName, path)
        this.news = _.concat(this.news, response)
        this.buildStatic()
        page = page + 1
        this.datesReset()
        this.loading = false
      } while (response.length === limit)
    },
    itemDisplay: function (item) {
      this.item = item
      this.$bvModal.show('modal')
    },
    searchReset: function () {
      this.search = ''
    },
    source: function () {
      let data = []
      let publisher = this.$route.params.id
      _.forEach(this.news, function (value, key) {
        if (value.publisher === publisher) {
          data.push(value)
        }
      })
      this.newsSelected = data
    },
    tag: function () {
      let data = []
      let tag = this.$route.params.id
      _.forEach(this.news, function (value, key) {
        if (
          _.find(value.tags, function (o) {
            return o.id === tag
          })
        ) {
          data.push(value)
        }
      })
      this.newsSelected = data
    }
  },
  watch: {
    $route (to, from) {
      if (!this.$route.params.page) {
        this.show.start = true
        this.show.news = false
      } else {
        this.show.start = false
        this.show.news = true
        this[this.$route.params.page]()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
body {
  padding-top: 100px;
}

.router-link-active {
  font-weight: 900
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 2s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
</style>
